import { Row, RowItem } from '_Elements/layout';

import { SVGClose } from '_Icons/main';
import { useCallback, useMemo, useState } from 'react';
import { Button, BUTTON_THEME } from '_Elements/button/v2';
import ListBox from './context-menu-popup-components/ListBox';
import './ContextMenuPopup.scss';
// eslint-disable-next-line import/no-internal-modules
import { Popover } from '@askyourteam-nz/react-library/dist/elements/tool-tip';

export const ContextMenuPopup = (props) => {
  const {
    title,
    options,
    onPrimaryClick: propsOnPrimaryClick,
    onSecondaryClick: propsOnSecondaryClick,
    secondaryIcon,
    selected: propsSelected,
    triggerRef,
    state,
    overlayRef
  } = props;

  const [selected, setSelected] = useState(propsSelected);

  const onChange = useCallback(
    (keySet = new Set()) => {
      const key = [...keySet][0];
      const option = options.find(({ value }) => value == key);
      setSelected(option.value);
    },
    [setSelected, options]
  );

  const onPrimaryClick = useCallback(
    () => {
      propsOnPrimaryClick(selected);
      state.close();
    },
    [selected, propsOnPrimaryClick, state]
  );

  const onSecondaryClick = useCallback(
    () => {
      propsOnSecondaryClick(selected);
      state.close();
    },
    [selected, propsOnSecondaryClick, state]
  );

  const values = useMemo(() => [selected], [selected]);

  return (
    <Popover
      popoverRef={overlayRef}
      style={{ width: 240, maxHeight: '100%' }}
      state={state}
      offset={-120}
      triggerRef={triggerRef}
      isNonModal
      placement={'top'}
    >
      <div className={'context-menu-popup__header'}>
        {title}
        <div className={'context-menu-popup__header-close'}>
          <SVGClose size={16} onClick={state.close} />
        </div>
      </div>
      <ListBox
        selectionMode={'single'}
        clearable={false}
        items={options}
        onChange={onChange}
        selectedKeys={values}
        autoFocus
        aria-label={'folder options'}
      />
      <Row className={'context-menu-popup__footer'}>
        <RowItem>
          <Button
            size={24}
            leadingIcon={secondaryIcon}
            theme={BUTTON_THEME.TEXT_PRIMARY}
            onClick={onSecondaryClick}
            dataAttributes={{
              'data-cy': 'context-menu-popup-secondary-button'
            }}
          >
            Remove from folder
          </Button>
        </RowItem>
        <RowItem>
          <Button
            size={32}
            theme={BUTTON_THEME.PRIMARY}
            onClick={onPrimaryClick}
            dataAttributes={{
              'data-cy': 'context-menu-popup-primary-button'
            }}
          >
            Add
          </Button>

        </RowItem>
      </Row>
    </Popover>
  );
};
