import { useButton, useOverlayTrigger, useFocusRing, useHover, mergeProps } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import { useCallback, useRef } from 'react';
import { SVGContextOptions } from '_Icons';
import { ListBox } from './context-menu-popup-components/ListBox';
// eslint-disable-next-line import/no-internal-modules
import { Popover } from '@askyourteam-nz/react-library/dist/elements/tool-tip';
import classNames from 'classnames';

import './SimpleContextMenu.scss';
import { SYSTEM_COLOR } from '_Lib/Enum';

export const SimpleContextMenu = (props) => {
  let overlayRef = useRef();
  let triggerRef = useRef();

  const {
    options,
    placement,
    offset,
    onChange: propsOnChange,
    ariaLabel
  } = props;

  let state = useOverlayTriggerState({});
  let { triggerProps } = useOverlayTrigger(
    { type: 'listbox' },
    state,
    triggerRef
  );

  const {
    buttonProps
  } = useButton(triggerProps, triggerRef);

  const {
    focusProps,
    isFocusVisible,
    isFocused
  } = useFocusRing({ within: true });

  const {
    isHovered,
    hoverProps
  } = useHover({});

  const {
    isOpen,
    close: closePopover,
  } = state;

  const onChange = useCallback(
    (value) => {
      propsOnChange(value);
      closePopover();
    },
    [closePopover]
  );

  return (
    <div className={'simple-context-menu'}>
      <button
        className={classNames(
          'simple-context-menu__button',
          {
            'simple-context-menu__button--hovered': isHovered,
            'simple-context-menu__button--focused': isFocused && isFocusVisible
          }
        )}
        aria-label={ariaLabel}
        {...mergeProps(buttonProps, focusProps, hoverProps)}
        ref={triggerRef}
      >
        <SVGContextOptions size={16} color={SYSTEM_COLOR.PRIMARY} />
      </button>
      {isOpen &&
        (
          <Popover
            triggerRef={triggerRef}
            popoverRef={overlayRef}
            state={state}
            offset={offset}
            placement={placement}
            style={{ width: 100 }}
          >
            <ListBox
              selectionMode={'single'}
              clearable={false}
              items={options}
              onChange={onChange}
              aria-label={ariaLabel}
            />
          </Popover>
        )}
    </div>
  );
};
