import { useRef, memo } from 'react';
import classNames from 'classnames';

import { Row, RowItem } from '_Elements/layout';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { useMenuOption } from '_Elements/selection';
import { SVGTickCircle } from '_Icons/main';
import { SYSTEM_COLOR } from '_Lib/Enum';

const Inner = memo(function Inner({ icon, rendered, selected }) {
  return (
    <Row spacing={4}>
      {icon && (
        <RowItem inflexible >
          {icon}
        </RowItem>
      )}
      <RowItem column flexible style={{ width: 0 }} >
        <P
          textSize={PARAGRAPH_SIZES.P2}
          style={{ fontWeight: 400 }}
        >
          {rendered}
        </P>
      </RowItem>
      {selected && (
        <RowItem right>
          <SVGTickCircle size={16} color={SYSTEM_COLOR.CHART.GREEN} />
        </RowItem>
      )}
    </Row>
  );
});

const Option = (props) => {
  const {
    item,
    state
  } = props;

  const ref = useRef();

  const {
    focused,
    pressed,
    hovered,
    selected,
    optionProps,
    option,
    rendered
  } = useMenuOption(
    {
      item,
    },
    state,
    ref
  );

  const {
    icon,
    label
  } = option || {};

  return (
    <li
      ref={ref}
      data-component={'context-menu-popup-list-box-item'}
      aria-label={label}
      className={
        classNames(
          'context-menu-popup__list-box__item',
          {
            ['context-menu-popup__list-box__item--focused']: focused,
            ['context-menu-popup__list-box__item--pressed']: pressed,
            ['context-menu-popup__list-box__item--hovered']: hovered,
            ['context-menu-popup__list-box__item--selected']: selected
          }
        )
      }
      {...optionProps}
    >
      <Inner
        icon={icon}
        rendered={rendered}
        selected={selected}
      />
    </li>
  );
};

export default Option;
