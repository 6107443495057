import { useRef } from 'react';
import { useMenuList } from '_Elements/selection';
import Option from './Option';

export const ListBox = (props) => {

  const ref = useRef();

  const {
    menuProps,
    state
  } = useMenuList(
    props,
    ref
  );

  const {
    collection
  } = state;

  return (
    <ul
      className={'context-menu-popup__list-box'}
      ref={ref}
      {...menuProps}
    >
      {[...collection].map(
        item => (
          <Option
            key={item.key}
            item={item}
            state={state}
          />
        )
      )}
    </ul>
  );
};

export default ListBox;
